

//GET
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"
//GET SINGLE USER

export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

export const GET_LINE_MANAGER = "GET_LINE_MANAGER"
export const GET_LINE_MANAGER_SUCCESS = "GET_LINE_MANAGER_SUCCESS"
export const GET_LINE_MANAGER_FAIL = "GET_LINE_MANAGER_FAIL"
//ADD
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"


export const UPDATE_SET_ROUTE = "UPDATE_SET_ROUTE"
export const UPDATE_SET_ROUTE_SUCCESS = "UPDATE_SET_ROUTE_SUCCESS"
export const UPDATE_SET_ROUTE_FAIL = "UPDATE_SET_ROUTE_FAIL"



export const LOADING_ADD_USER = "LOADING_ADD_USER"

// Update
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS =
    "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

//Delete
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS =
    "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

//Others
export const STORE_USER_DATA = "STORE_USER_DATA"

export const DELETE_USER_ROUTE = "DELETE_USER_ROUTE"
export const DELETE_USER_ROUTE_SUCCESS = "DELETE_USER_ROUTE_SUCCESS"
export const DELETE_USER_ROUTE_FAIL = "DELETE_USER_ROUTE_FAIL"


export const USER_INFO_DATA = "USER_INFO_DATA"
export const USER_INFO_DATA_SUCCESS = "USER_INFO_DATA_SUCCESS"
export const USER_INFO_DATA_FAIL = "USER_INFO_DATA_FAIL"


export const USER_PERFORMANCE = "USER_PERFORMANCE"
export const USER_PERFORMANCE_SUCCESS = "USER_PERFORMANCE_SUCCESS"
export const USER_PERFORMANCE_FAIL = "USER_PERFORMANCE_FAIL"


export const SAMPLE_EXCEL_DOWNLOAD= "SAMPLE_EXCEL_DOWNLOAD"
export const SAMPLE_EXCEL_DOWNLOAD_SUCCESS = "SAMPLE_EXCEL_DOWNLOAD_SUCCESS"
export const SAMPLE_EXCEL_DOWNLOAD_FAIL = "SAMPLE_EXCEL_DOWNLOAD_FAIL"


