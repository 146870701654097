import {
  AREA_FILTER_CHANGED,
  CLEAR_FILTERS,
  GET_AREA_BY_REGION,
  GET_AVG_INVOICE_VALUE,
  GET_AVG_INVOICE_VALUE_FAIL,
  GET_AVG_INVOICE_VALUE_SUCCESS,
  GET_BOUNCE_DATA,
  GET_BOUNCE_DATA_FAIL,
  GET_BOUNCE_DATA_SUCCESS,
  GET_DASHBOARD_CHART,
  GET_DASHBOARD_CHART_FAIL,
  GET_DASHBOARD_CHART_SUCCESS,
  GET_DASHBOARD_OVERVIEW,
  GET_DASHBOARD_OVERVIEW_FAIL,
  GET_DASHBOARD_OVERVIEW_SUCCESS,
  GET_MAP_DATA,
  GET_MAP_DATA_FAIL,
  GET_MAP_DATA_SUCCESS,
  GET_PRE_ORDER_DELIVERY,
  GET_PRE_ORDER_DELIVERY_FAIL,
  GET_PRE_ORDER_DELIVERY_SUCCESS,
  GET_REVENUE_INFO,
  GET_REVENUE_INFO_FAIL,
  GET_REVENUE_INFO_SUCCESS,
  GET_SELLING_OVERVIEW,
  GET_SELLING_OVERVIEW_FAIL,
  GET_SELLING_OVERVIEW_SUCCESS,
  GET_TARGET_ANALYSIS,
  GET_TARGET_ANALYSIS_FAIL,
  GET_TARGET_ANALYSIS_SUCCESS,
  GET_TERRITORY_BY_AREA,
  GET_TOP_BRAND,
  GET_TOP_BRAND_FAIL,
  GET_TOP_BRAND_SUCCESS,
  GET_TOP_DP,
  GET_TOP_DP_FAIL,
  GET_TOP_DP_SUCCESS,
  GET_TOP_PRODUCT,
  GET_TOP_PRODUCT_FAIL,
  GET_TOP_PRODUCT_SUCCESS,
  GET_TOP_REGION,
  GET_TOP_REGION_FAIL,
  GET_TOP_REGION_SUCCESS,
  GET_TOP_SR,
  GET_TOP_SR_FAIL,
  GET_TOP_SR_SUCCESS,
  GET_TOTAL_DP,
  GET_TOTAL_DP_FAIL,
  GET_TOTAL_DP_SUCCESS,
  GET_TOTAL_SR,
  GET_TOTAL_SR_FAIL,
  GET_TOTAL_SR_SUCCESS,
  MAIN_FILTER_CHANGED,
  REGION_FILTER_CHANGED,
  STORE_DASHBOARD_DATA,
  TERRITORY_FILTER_CHANGED,
  TOGGLE_DASHBOARD_DATA,
} from "./actionTypes"

export const getDashboardOverview = authtoken => ({
  type: GET_DASHBOARD_OVERVIEW,
  payload: { authtoken },
})

export const getDashboardOverviewSuccess = (data, authtoken) => ({
  type: GET_DASHBOARD_OVERVIEW_SUCCESS,
  payload: { data, authtoken },
})

export const getDashboardOverviewFail = error => ({
  type: GET_DASHBOARD_OVERVIEW_FAIL,
  payload: error,
})
export const getSellingOverview = (authtoken, query) => ({
  type: GET_SELLING_OVERVIEW,
  payload: { authtoken, query },
})

export const getSellingOverviewSuccess = (data, authtoken) => ({
  type: GET_SELLING_OVERVIEW_SUCCESS,
  payload: { data, authtoken },
})

export const getSellingOverviewFail = error => ({
  type: GET_SELLING_OVERVIEW_FAIL,
  payload: error,
})

export const getDashboardChart = (authtoken, query) => ({
  type: GET_DASHBOARD_CHART,
  payload: { authtoken, query },
})

export const getDashboardChartSuccess = (data, authtoken) => ({
  type: GET_DASHBOARD_CHART_SUCCESS,
  payload: { data, authtoken },
})

export const getDashboardChartFail = error => ({
  type: GET_DASHBOARD_CHART_FAIL,
  payload: error,
})
export const getTopProduct = (authtoken, query) => ({
  type: GET_TOP_PRODUCT,
  payload: { authtoken, query },
})

export const getTopProductSuccess = (data, authtoken) => ({
  type: GET_TOP_PRODUCT_SUCCESS,
  payload: { data, authtoken },
})

export const getTopProductFail = error => ({
  type: GET_TOP_PRODUCT_FAIL,
  payload: error,
})

export const getTotalSR = (authtoken, query) => ({
  type: GET_TOTAL_SR,
  payload: { authtoken, query },
})

export const getTotalSRSuccess = (data, authtoken) => ({
  type: GET_TOTAL_SR_SUCCESS,
  payload: { data, authtoken },
})

export const getTotalSRFail = error => ({
  type: GET_TOTAL_SR_FAIL,
  payload: error,
})
export const getTotalDp = (authtoken, id) => ({
  type: GET_TOTAL_DP,
  payload: { authtoken, id },
})

export const getTotalDpSuccess = (data, authtoken) => ({
  type: GET_TOTAL_DP_SUCCESS,
  payload: { data, authtoken },
})

export const getTotalDpFail = error => ({
  type: GET_TOTAL_DP_FAIL,
  payload: error,
})

export const getTopBrand = (authtoken, query) => ({
  type: GET_TOP_BRAND,
  payload: { authtoken, query },
})

export const getTopBrandSuccess = (data, authtoken) => ({
  type: GET_TOP_BRAND_SUCCESS,
  payload: { data, authtoken },
})

export const getTopBrandFail = error => ({
  type: GET_TOP_BRAND_FAIL,
  payload: error,
})
export const getTopRegion = (authtoken, query) => ({
  type: GET_TOP_REGION,
  payload: { authtoken, query },
})

export const getTopRegionSuccess = (data, authtoken) => ({
  type: GET_TOP_REGION_SUCCESS,
  payload: { data, authtoken },
})

export const getTopRegionFail = error => ({
  type: GET_TOP_REGION_FAIL,
  payload: error,
})
export const getTopSR = (authtoken, query) => ({
  type: GET_TOP_SR,
  payload: { authtoken, query },
})

export const getTopSRSuccess = (data, authtoken) => ({
  type: GET_TOP_SR_SUCCESS,
  payload: { data, authtoken },
})

export const getTopSRFail = error => ({
  type: GET_TOP_SR_FAIL,
  payload: error,
})

export const getTopDP = (authtoken, id) => ({
  type: GET_TOP_DP,
  payload: { authtoken, id },
})

export const getTopDPSuccess = (data, authtoken) => ({
  type: GET_TOP_DP_SUCCESS,
  payload: { data, authtoken },
})

export const getTopDPFail = error => ({
  type: GET_TOP_DP_FAIL,
  payload: error,
})
export const getPreDelivery = (authtoken, query) => ({
  type: GET_PRE_ORDER_DELIVERY,
  payload: { authtoken, query },
})

export const getPreDeliverySuccess = (data, authtoken) => ({
  type: GET_PRE_ORDER_DELIVERY_SUCCESS,
  payload: { data, authtoken },
})

export const getPreDeliveryFail = error => ({
  type: GET_PRE_ORDER_DELIVERY_FAIL,
  payload: error,
})

export const storeDashboardReducerData = (name, data, zoom) => ({
  type: STORE_DASHBOARD_DATA,
  payload: { name, data, zoom },
})

export const toggleDashboardData = name => ({
  type: TOGGLE_DASHBOARD_DATA,
  payload: { name },
})

export const getOrderBounce = authtoken => ({
  type: GET_BOUNCE_DATA,
  payload: { authtoken },
})

export const getOrderBounceSuccess = (data, authtoken) => ({
  type: GET_BOUNCE_DATA_SUCCESS,
  payload: { data, authtoken },
})

export const getOrderBounceFail = error => ({
  type: GET_BOUNCE_DATA_FAIL,
  payload: error,
})

export const getAvgInvoiceValue = (authtoken, query) => ({
  type: GET_AVG_INVOICE_VALUE,
  payload: { authtoken, query },
})

export const getAvgInvoiceValueSuccess = (data, authtoken) => ({
  type: GET_AVG_INVOICE_VALUE_SUCCESS,
  payload: { data, authtoken },
})

export const getAvgInvoiceValueFail = error => ({
  type: GET_AVG_INVOICE_VALUE_FAIL,
  payload: error,
})

// GET AREA BY REGION

export const getAreaByRegion = (token, id) => ({
  type: GET_AREA_BY_REGION,
  payload: { id, token },
})

export const getTerritoryByArea = (token, id) => ({
  type: GET_TERRITORY_BY_AREA,
  payload: { id, token },
})
// export const getAreaByRegionSuccess = () => ({type : GET_AREA_BY_REGION, })
// export const getAreaByRegion = () => ({type : GET_AREA_BY_REGION})
export const getTargetAnalysis = (authtoken, query) => ({
  type: GET_TARGET_ANALYSIS,
  payload: { authtoken, query },
})

export const getTargetAnalysisSuccess = (data, authtoken) => ({
  type: GET_TARGET_ANALYSIS_SUCCESS,
  payload: { data, authtoken },
})

export const getTargetAnalysisFail = error => ({
  type: GET_TARGET_ANALYSIS_FAIL,
  payload: error,
})

export const clearFilters = () => ({ type: CLEAR_FILTERS })
export const onMainFilterChange = data => ({
  type: MAIN_FILTER_CHANGED,
  payload: data,
})

export const onRegionFilterChange = data => ({
  type: REGION_FILTER_CHANGED,
  payload: data,
})

export const onAreaFilterChange = data => ({
  type: AREA_FILTER_CHANGED,
  payload: data,
})

export const onTerritoryFilterChange = data => ({
  type: TERRITORY_FILTER_CHANGED,
  payload: data,
})
export const getRevenueInfo = (authtoken, query) => ({
  type: GET_REVENUE_INFO,
  payload: { authtoken, query },
})

export const getRevenueInfoSuccess = (data, authtoken) => ({
  type: GET_REVENUE_INFO_SUCCESS,
  payload: { data, authtoken },
})

export const getRevenueInfoFail = error => ({
  type: GET_REVENUE_INFO_FAIL,
  payload: error,
})

export const getMapData = payload => ({
  type: GET_MAP_DATA,
  payload,
})

export const getMapDataSuccess = data => ({
  type: GET_MAP_DATA_SUCCESS,
  payload: data,
})

export const getMapDataFail = error => ({
  type: GET_MAP_DATA_FAIL,
  payload: error,
})
