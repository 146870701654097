import {
  addStoresedata,
  getAllStoresData,
  getSearchStoresData,
  getShopGraphData,
  getShopMap,
  getShopOrderExcel,
  getSingleShopMap,
  getStoreAddExcel,
  getStoreAnalyticData,
  getStoreTopProduct,
  getStoredata,
  getStoresedata,
  getVisitedShopExcel,
  getVisitedShopNotOrderedExcel,
} from "helpers/backend_helper"
import { toast } from "react-toastify"
import { call, delay, put, takeEvery } from "redux-saga/effects"
import {
  ADD_EXCLUSIVE_SHOP,
  ADD_NEW_STORE,
  GET_ALL_STORE,
  GET_SEARCH_STORE,
  GET_SHOP_DETAILS_MAP,
  GET_SHOP_GRAPH,
  GET_SHOP_MAP,
  GET_STORE,
  GET_STORES,
  GET_STORE_ADD_EXCEL,
  GET_STORE_ANALYTIC,
  GET_STORE_ORDER,
  GET_STORE_TOP_PRODUCT,
  GET_VISITED_SHOP,
  UPDATE_STORE,
  VISITED_SHOP_ORDER_NOT,
} from "./actionTypes"
import {
  addExclusiveShopFail,
  addExclusiveShopSuccess,
  addStoreFail,
  addStoreSuccess,
  getAllStoreFail,
  getAllStoreSuccess,
  getSearchStoreStoreFail,
  getSearchStoreSuccess,
  getShopDetailsMapFail,
  getShopDetailsMapSuccess,
  getShopGraphFail,
  getShopGraphSuccess,
  getShopMapFail,
  getShopMapSuccess,
  getStoreEXCELFail,
  getStoreEXCELSuccess,
  getStoreFail,
  getStoreSuccess,
  getStoreTopProductFail,
  getStoreTopProductSuccess,
  getStoresAnalyticFail,
  getStoresAnalyticSuccess,
  getStoresFail,
  getStoresOrderFail,
  getStoresOrderSuccess,
  getStoresSuccess,
  getVisitedShopFail,
  getVisitedShopNotOrderFail,
  getVisitedShopNotOrderSuccess,
  getVisitedShopSuccess,
  updateStoreFail,
  updateStoreSuccess,
} from "./actions"
//add new store
function* workerAddNewStore({ payload: { data, history, authtoken } }) {
  try {
    //  console.log("hello data saga", data)
    const response = yield call(addStoresedata, data, authtoken)
    //  console.log("store", response)
    yield put(addStoreSuccess(response))
    toast.success("success")
    history.push("/store-info")
  } catch (error) {
    //  console.log(error)
    if (!error.response) {
    } else {
      let message = error.response.data.message
      yield put(addStoreFail(message))
      toast.error(message)
    }
  }
}
function* fetchStoreAnalytic({ payload: { authtoken, id } }) {
  try {
    //  console.log(authtoken)
    //  console.log(id)
    const response = yield call(getStoreAnalyticData, authtoken, id)
    yield put(getStoresAnalyticSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getStoresAnalyticFail(error))
  }
}
function* fetchStoreOrder({ payload: { authtoken, pageNo, limit } }) {
  try {
    const response = yield call(getShopOrderExcel, authtoken, pageNo, limit)

    yield put(getStoresOrderSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getStoresOrderFail(error))
  }
}
// function* fetchStoreOrder({ payload: { authtoken, pageNo, limit } }) {
// //  console.log('id',id);
//   try {
// console.log('authtoken', authtoken);
//     const url = `shop/shop-preorder?pageNo=${pageNo}&limit=${limit}`
//     const response = yield call(getData, url, authtoken)

//     yield put(getStoresOrderSuccess(response))
//   } catch (error) {
//     yield put(getStoresOrderFail(error))
//   }
// }
function* addExclusiveShop({ payload: { data, history, authtoken } }) {
  try {
    const response = yield call(addProduct, data, authtoken)
    //  console.log("hi")
    //  console.log(response)
    yield put(addExclusiveShopSuccess(response))
    toast(":unicorn_face: Exclusive shop added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    // history.push("/store-info")
  } catch (error) {
    if (!error.response) {
      toast("Exclusive shop added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      // history.push("/store-info")
    } else {
      let message = error.response.data.message
      yield put(addExclusiveShopFail(message))
      // console.log("hi",message);
      toast.error(message)
    }
  }
}
//getStores
function* fetchAllStores({ payload: { authtoken, orgid } }) {
  try {
    delay(500)
    //  console.log("fetched area")
    const response = yield call(getStoresedata, authtoken, orgid)
    //  console.log("HELLO", response)
    if (response.status === "success") {
      //  console.log("success")
      yield put(getStoresSuccess(response))
    }
    // yield put(getStoresSuccess(response.shop))
  } catch (error) {
    yield put(getStoresFail(error))
  }
}
//getStore - single
//axios
//updateStore
//axios
const asyncUpdateStore = async (data, id, authtoken) => {
  try {
    const response = await axiosUpdateStore(data, id, authtoken)
    //  console.log("response data of singleId", response.data)
    return response.data
  } catch (error) {
    //  console.log("hello response", error.response.data)
    return error.response.data
  }
}
//WorkerGetStore - single
function* workerUpdateStore({
  payload: { values, storeid, history, authtoken },
}) {
  //  console.log("hello delete Store", values, storeid, history, authtoken)
  try {
    const response = yield call(asyncUpdateStore, values, storeid, authtoken)
    if (response.status == "success") {
      yield put(updateStoreSuccess("Data has been updated"))
      //  console.log("hello bd ami 169")
      toast.success("Data Successfuly updated")
      //  console.log("hello data")
      history.push(`/storeprofile/${storeid}`)
    }
  } catch (error) {
    yield put(updateStoreFail("Something error"))
    toast.error("Something wrong")
  }
}
function* fetchStoreTopProducts({ payload: { authtoken, id } }) {
  try {
    //  console.log(authtoken)
    const response = yield call(getStoreTopProduct, authtoken, id)
    //  console.log(response)
    yield put(getStoreTopProductSuccess(response))
  } catch (error) {
    yield put(getStoreTopProductFail(error))
    //  console.log("hi")
  }
}
function* fetchStore({ payload: { authtoken, id } }) {
  try {
    //  console.log(authtoken)
    const response = yield call(getStoredata, authtoken, id)
    //  console.log(response)
    yield put(getStoreSuccess(response))
  } catch (error) {
    yield put(getStoreFail(error))
    //  console.log("hi")
  }
}
function* fetchAllStoresData({
  payload: { authtoken, orgId, currentPage, shopName },
}) {
  try {
    delay(500)
    const response = yield call(
      getAllStoresData,
      authtoken,
      orgId,
      currentPage,
      shopName
    )
    //  console.log("HELLO", response)
    if (response.status === "success") {
      //  console.log("success")
      yield put(getAllStoreSuccess(response))
    }
    // yield put(getStoresSuccess(response.shop))
  } catch (error) {
    yield put(getAllStoreFail(error))
  }
}
function* fetchSearchStoresData({ payload: { authtoken, shopName } }) {
  try {
    delay(500)
    const response = yield call(getSearchStoresData, authtoken, shopName)
    //  console.log("HELLO", response)
    if (response.status === "success") {
      //  console.log("success")
      yield put(getSearchStoreSuccess(response))
    }
    // yield put(getStoresSuccess(response.shop))
  } catch (error) {
    yield put(getSearchStoreStoreFail(error))
  }
}
function* fetchStoreAddExcel({ payload: { authtoken, data } }) {
  //  console.log('data',data);
  try {
    delay(500)
    //  console.log(data);
    const response = yield call(getStoreAddExcel, authtoken, data)
    //  console.log('fetchAttendance',response);
    yield put(getStoreEXCELSuccess(response))
    // console.log('line 15', response.data.users);
  } catch (error) {
    yield put(getStoreEXCELFail(error))
    //  console.log('hi');
  }
}

function* fetchVisitedShopExcel({ payload: { authtoken, pageNo, limit } }) {
  try {
    const response = yield call(getVisitedShopExcel, authtoken, pageNo, limit)

    yield put(getVisitedShopSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getVisitedShopFail(error))
  }
}

function* fetchVisitedShopNotOrderedExcel({
  payload: { authtoken, fromDate, toDate, pageNo, limit, region },
}) {
  try {
    const response = yield call(
      getVisitedShopNotOrderedExcel,
      authtoken,
      fromDate,
      toDate,
      pageNo,
      limit,
      region
    )

    yield put(getVisitedShopNotOrderSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getVisitedShopNotOrderFail(error))
  }
}

function* fetchStoreMap({
  payload: { authtoken, page, limit, region, area, territory, searchFilter },
}) {
  try {
    const response = yield call(
      getShopMap,
      authtoken,
      page,
      limit,
      region,
      area,
      territory,
      searchFilter
    )

    yield put(getShopMapSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getShopMapFail(error))
  }
}

function* fetchSingleShopMap({ payload: { authtoken, shopId } }) {
  try {
    const response = yield call(getSingleShopMap, authtoken, shopId)

    yield put(getShopDetailsMapSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getShopDetailsMapFail(error))
  }
}

function* fetchShopGraph({ payload: { authtoken, id } }) {
  try {
    const response = yield call(getShopGraphData, authtoken, id)

    yield put(getShopGraphSuccess(response))
  } catch (error) {
    //  console.log("27")
    yield put(getShopGraphFail(error))
  }
}
function* storeSaga() {
  yield takeEvery(ADD_NEW_STORE, workerAddNewStore)
  yield takeEvery(GET_STORES, fetchAllStores)
  // yield takeEvery(GET_STORE, workerGetStore)
  yield takeEvery(GET_STORE, fetchStore)
  yield takeEvery(UPDATE_STORE, workerUpdateStore)
  yield takeEvery(GET_STORE_TOP_PRODUCT, fetchStoreTopProducts)
  yield takeEvery(ADD_EXCLUSIVE_SHOP, addExclusiveShop)
  yield takeEvery(GET_STORE_ANALYTIC, fetchStoreAnalytic)
  yield takeEvery(GET_ALL_STORE, fetchAllStoresData)
  yield takeEvery(GET_SEARCH_STORE, fetchSearchStoresData)
  yield takeEvery(GET_STORE_ADD_EXCEL, fetchStoreAddExcel)
  yield takeEvery(GET_STORE_ORDER, fetchStoreOrder)
  yield takeEvery(GET_VISITED_SHOP, fetchVisitedShopExcel)
  yield takeEvery(VISITED_SHOP_ORDER_NOT, fetchVisitedShopNotOrderedExcel)
  yield takeEvery(GET_SHOP_MAP, fetchStoreMap)
  yield takeEvery(GET_SHOP_DETAILS_MAP, fetchSingleShopMap)
  yield takeEvery(GET_SHOP_GRAPH, fetchShopGraph)
}
export default storeSaga
