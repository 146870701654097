export const GET_DASHBOARD_OVERVIEW = "GET_DASHBOARD_OVERVIEW"
export const GET_DASHBOARD_OVERVIEW_SUCCESS = "GET_DASHBOARD_OVERVIEW_SUCCESS"
export const GET_DASHBOARD_OVERVIEW_FAIL = "GET_DASHBOARD_OVERVIEW_FAIL"

export const GET_DASHBOARD_CHART = "GET_DASHBOARD_CHART "
export const GET_DASHBOARD_CHART_SUCCESS = "GET_DASHBOARD_CHART_SUCCESS"
export const GET_DASHBOARD_CHART_FAIL = "GET_DASHBOARD_CHART_FAIL"

export const GET_SELLING_OVERVIEW = "GET_SELLING_OVERVIEW "
export const GET_SELLING_OVERVIEW_SUCCESS = "GET_SELLING_OVERVIEW_SUCCESS"
export const GET_SELLING_OVERVIEW_FAIL = "GET_SELLING_OVERVIEW_FAIL"

export const GET_TOTAL_SR = "GET_TOTAL_SR "
export const GET_TOTAL_SR_SUCCESS = "GET_TOTAL_SR_SUCCESS"
export const GET_TOTAL_SR_FAIL = "GET_TOTAL_SR_FAIL"

export const GET_TOTAL_DP = "GET_TOTAL_DP "
export const GET_TOTAL_DP_SUCCESS = "GET_TOTAL_DP_SUCCESS"
export const GET_TOTAL_DP_FAIL = "GET_TOTAL_DP_FAIL"

// TOP BRAND
export const GET_TOP_BRAND = "GET_TOP_BRAND "
export const GET_TOP_BRAND_SUCCESS = "GET_TOP_BRAND_SUCCESS"
export const GET_TOP_BRAND_FAIL = "GET_TOP_BRAND_FAIL"
// TOP REGION
export const GET_TOP_REGION = "GET_TOP_REGION "
export const GET_TOP_REGION_SUCCESS = "GET_TOP_REGION_SUCCESS"
export const GET_TOP_REGION_FAIL = "GET_TOP_REGION_FAIL"

// TOP PRODUCT

export const GET_TOP_PRODUCT = "GET_TOP_PRODUCT "
export const GET_TOP_PRODUCT_SUCCESS = "GET_TOP_PRODUCT_SUCCESS"
export const GET_TOP_PRODUCT_FAIL = "GET_TOP_PRODUCT_FAIL"
// TOP SR

export const GET_TOP_SR = "GET_TOP_SR "
export const GET_TOP_SR_SUCCESS = "GET_TOP_SR_SUCCESS"
export const GET_TOP_SR_FAIL = "GET_TOP_SR_FAIL"

// TOP DP

export const GET_TOP_DP = "GET_TOP_DP "
export const GET_TOP_DP_SUCCESS = "GET_TOP_DP_SUCCESS"
export const GET_TOP_DP_FAIL = "GET_TOP_DP_FAIL"

export const GET_PRE_ORDER_DELIVERY = "GET_PRE_ORDER_DELIVERY "
export const GET_PRE_ORDER_DELIVERY_SUCCESS = "GET_PRE_ORDER_DELIVERY_SUCCESS"
export const GET_PRE_ORDER_DELIVERY_FAIL = "GET_PRE_ORDER_DELIVERY_FAIL"
export const STORE_DASHBOARD_DATA = "STORE_DASHBOARD_DATA"
export const TOGGLE_DASHBOARD_DATA = "TOGGLE_DASHBOARD_DATA"

export const GET_BOUNCE_DATA = "GET_BOUNCE_DATA"
export const GET_BOUNCE_DATA_SUCCESS = "GET_BOUNCE_DATA_SUCCESS"
export const GET_BOUNCE_DATA_FAIL = "GET_BOUNCE_DATA_FAIL"

export const GET_AVG_INVOICE_VALUE = "GET_AVG_INVOICE_VALUE"
export const GET_AVG_INVOICE_VALUE_SUCCESS = "GET_AVG_INVOICE_VALUE_SUCCESS"
export const GET_AVG_INVOICE_VALUE_FAIL = "GET_AVG_INVOICE_VALUE_FAIL"

export const GET_TARGET_ANALYSIS = "GET_TARGET_ANALYSIS"
export const GET_TARGET_ANALYSIS_SUCCESS = "GET_TARGET_ANALYSIS_SUCCESS"
export const GET_TARGET_ANALYSIS_FAIL = "GET_TARGET_ANALYSIS_FAIL"

export const GET_REVENUE_INFO = "GET_REVENUE_INFO"
export const GET_REVENUE_INFO_SUCCESS = "GET_REVENUE_INFO_SUCCESS"
export const GET_REVENUE_INFO_FAIL = "GET_REVENUE_INFO_FAIL"

// GET AREA BY REGION
export const GET_AREA_BY_REGION = "GET_AREA_BY_REGION"
export const GET_AREA_BY_REGION_SUCCESS = "GET_AREA_BY_REGION_SUCCESS"
export const GET_AREA_BY_REGION_FAIL = "GET_AREA_BY_REGION_FAIL"

// GET TERRITORY BY AREA
export const GET_TERRITORY_BY_AREA = "GET_TERRITORY_BY_AREA"
export const GET_TERRITORY_BY_AREA_SUCCESS = "GET_TERRITORY_BY_AREA_SUCCESS"
export const GET_TERRITORY_BY_AREA_FAIL = "GET_TERRITORY_BY_AREA_FAIL"

// GET MAP DATA
export const GET_MAP_DATA = "GET_MAP_DATA"
export const GET_MAP_DATA_SUCCESS = "GET_MAP_DATA_SUCCESS"
export const GET_MAP_DATA_FAIL = "GET_MAP_DATA_FAIL"

// filter action

export const MAIN_FILTER_CHANGED = "MAIN_FILTER_CHANGED"
export const REGION_FILTER_CHANGED = "REGION_FILTER_CHANGED"
export const AREA_FILTER_CHANGED = "AREA_FILTER_CHANGED"
export const TERRITORY_FILTER_CHANGED = "TERRITORY_FILTER_CHANGED"
export const CLEAR_FILTERS = "CLEAR_FILTERS"

