export const GET_STORES = "GET_STORES"
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS"
export const GET_STORES_FAIL = "GET_STORES_FAIL"

//Get Single store

export const GET_STORE = "GET_STORE"
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS"
export const GET_STORE_FAIL = "GET_STORE_FAIL"

export const GET_STORE_ORDER = "GET_STORE_ORDER"
export const GET_STORE_ORDER_SUCCESS = "GET_STORE_ORDER_SUCCESS"
export const GET_STORE_ORDER_FAIL = "GET_STORE_ORDER_FAIL"

export const GET_STORE_ANALYTIC = "GET_STORE_ANALYTIC"
export const GET_STORE_ANALYTIC_SUCCESS = "GET_STORE_ANALYTIC_SUCCESS"
export const GET_STORE_ANALYTIC_FAIL = "GET_STORE_ANALYTIC_FAIL"

export const GET_STORE_TOP_PRODUCT = "GET_STORE_TOP_PRODUCT"
export const GET_STORE_TOP_PRODUCT_SUCCESS = "GET_STORE_TOP_PRODUCT_SUCCESS"
export const GET_STORE_TOP_PRODUCT_FAIL = "GET_STORE_TOP_PRODUCT_FAIL"

//ADD
export const ADD_NEW_STORE = "ADD_NEW_STORE"
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS"
export const ADD_STORE_FAIL = "ADD_STORE_FAIL"
// ADD EXCLUSIVE SHOP

export const ADD_EXCLUSIVE_SHOP = "ADD_EXCLUSIVE_SHOP"
export const ADD_EXCLUSIVE_SHOP_SUCCESS = "ADD_EXCLUSIVE_SHOP_SUCCESS"
export const ADD_EXCLUSIVE_SHOP_FAIL = "ADD_EXCLUSIVE_SHOP_FAIL"

// Update
export const UPDATE_STORE = "UPDATE_STORE"
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS"
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL"

//Delete
export const DELETE_STORE = "DELETE_STORE"
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS"
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL"

//Others
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const GET_ALL_STORE = "GET_ALL_STORE"
export const GET_ALL_STORE_SUCCESS = "GET_ALL_STORE_SUCCESS"
export const GET_ALL_STORE_FAIL = "GET_ALL_STORE_FAIL"


export const GET_SEARCH_STORE = "GET_SEARCH_STORE"
export const GET_SEARCH_STORE_SUCCESS = "GET_SEARCH_STORE_SUCCESS"
export const GET_SEARCH_STORE_FAIL = "GET_SEARCH_STORE_FAIL"


export const GET_STORE_ADD_EXCEL = "STORE_ADD_EXCEL"
export const GET_STORE_ADD_EXCEL_SUCCESS = "STORE_ADD_EXCEL_SUCCESS"
export const GET_STORE_ADD_EXCEL_FAIL = "STORE_ADD_EXCEL_FAIL"

export const GET_VISITED_SHOP = "GET_VISITED_SHOP"
export const GET_VISITED_SHOP_SUCCESS = "GET_VISITED_SHOP_SUCCESS"
export const GET_VISITED_SHOP_FAIL = "GET_VISITED_SHOP_FAIL"

export const VISITED_SHOP_ORDER_NOT = "VISITED_SHOP_ORDER_NOT"
export const VISITED_SHOP_ORDER_NOT_SUCCESS = "VISITED_SHOP_ORDER_NOT_SUCCESS"
export const VISITED_SHOP_ORDER_NOT_FAIL = "VISITED_SHOP_ORDER_NOT_FAIL"



export const GET_SHOP_MAP = "GET_SHOP_MAP"
export const GET_SHOP_MAP_SUCCESS = "GET_SHOP_MAP_SUCCESS"
export const GET_SHOP_MAP_FAIL = "GET_SHOP_MAP_FAIL"

export const GET_SHOP_DETAILS_MAP = "GET_SHOP_DETAILS_MAP"
export const GET_SHOP_DETAILS_MAP_SUCCESS = "GET_SHOP_DETAILS_MAP_SUCCESS"
export const GET_SHOP_DETAILS_MAP_FAIL = "GET_SHOP_DETAILS_MAP_FAIL"


export const GET_SHOP_GRAPH = "GET_SHOP_GRAPH"
export const GET_SHOP_GRAPH_SUCCESS = "GET_SHOP_GRAPH_SUCCESS"
export const GET_SHOP_GRAPH_FAIL = "GET_SHOP_GRAPH_FAIL"

// Search Filter
export const SEARCH_FILTER = "SEARCH_FILTER"