import {
  ADD_NEW_USER,
  ADD_USER_FAIL,
  ADD_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  GET_LINE_MANAGER,
  GET_LINE_MANAGER_FAIL,
  GET_LINE_MANAGER_SUCCESS,
  GET_USER,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  LOADING_ADD_USER,
  STORE_USER_DATA,
  UPDATE_SET_ROUTE,
  UPDATE_SET_ROUTE_FAIL,
  UPDATE_SET_ROUTE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  DELETE_USER_ROUTE,
  DELETE_USER_ROUTE_SUCCESS,
  DELETE_USER_ROUTE_FAIL,
  USER_INFO_DATA,
  USER_INFO_DATA_SUCCESS,
  USER_INFO_DATA_FAIL,
  USER_PERFORMANCE,
  USER_PERFORMANCE_SUCCESS,
  USER_PERFORMANCE_FAIL,
  SAMPLE_EXCEL_DOWNLOAD,
  SAMPLE_EXCEL_DOWNLOAD_SUCCESS,
  SAMPLE_EXCEL_DOWNLOAD_FAIL
} from "./actionTypes"

export const getUsers = (authtoken, orgid, region) => ({
  type: GET_USERS,
  payload: { authtoken, orgid, region },
})

export const getUsersSuccess = data => ({
  type: GET_USERS_SUCCESS,
  payload: data,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})
export const getLineManager = (authtoken, role) => ({
  type: GET_LINE_MANAGER,
  payload: { authtoken, role },
})

export const getLineManagerSuccess = data => ({
  type: GET_LINE_MANAGER_SUCCESS,
  payload: data,
})

export const getLineManagerFail = error => ({
  type: GET_LINE_MANAGER_FAIL,
  payload: error,
})

export const getUser = id => ({
  type: GET_USER,
  payload: id,
})

export const LoadingUserAdd = isLoading => ({
  type: LOADING_ADD_USER,
  payload: isLoading,
})

export const getUserSuccess = (data, authtoken) => ({
  type: GET_USER_SUCCESS,
  payload: { data, authtoken },
})

export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const addNewUser = (data, history, authtoken) => ({
  type: ADD_NEW_USER,
  payload: { data, history, authtoken },
})

export const addUserSuccess = data => ({
  type: ADD_USER_SUCCESS,
  payload: data,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const updateRouteUser = (id, data, authtoken, history) => ({
  type: UPDATE_SET_ROUTE,
  payload: { id, data, authtoken, history },
})

export const updateRouteUserSuccess = (id, data) => ({
  type: UPDATE_SET_ROUTE_SUCCESS,
  payload: { id, data },
})

export const updateRouteUserFail = error => ({
  type: UPDATE_SET_ROUTE_FAIL,
  payload: error,
})

export const updateUser = (id, data, token, history) => ({
  type: UPDATE_USER,
  payload: { data, history, token, id },
})

export const updateUserSuccess = (id, data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { id, data },
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = data => ({
  type: DELETE_USER,
  payload: data,
})

export const deleteUserSuccess = data => ({
  type: DELETE_USER_SUCCESS,
  payload: data,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})
export const storeUserData = (name, data) => ({
  type: STORE_USER_DATA,
  payload: { name, data },
})

export const deleteRouteUser = ( authtoken, userId, routeId) => ({
  type: DELETE_USER_ROUTE,
  payload: { authtoken, userId, routeId},
})

export const deleteRouteUserSuccess = (id, data) => ({
  type: DELETE_USER_ROUTE_SUCCESS,
  payload: { id, data },
})

export const deleteRouteUserFail = error => ({
  type: DELETE_USER_ROUTE_FAIL,
  payload: error,
})
export const getUsersInfo = (authtoken, id, currentPage, value, role, region) => ({
  type: USER_INFO_DATA,
  payload: { authtoken, id, currentPage, value, role, region},
})

export const getUsersInfoSuccess = data => ({
  type: USER_INFO_DATA_SUCCESS,
  payload: data,
})

export const getUsersInfoFail = error => ({
  type: USER_INFO_DATA_FAIL,
  payload: error,
})
export const userPerformance = (authtoken, id, currentPage, value, role) => ({
  type: USER_PERFORMANCE,
  payload: { authtoken, id, currentPage, value, role  },
})

export const userPerformanceSuccess = data => ({
  type: USER_PERFORMANCE_SUCCESS,
  payload: data,
})

export const userPerformanceFail = error => ({
  type: USER_PERFORMANCE_FAIL,
  payload: error,
})
export const getSampleExcel = (authtoken, id) => ({
  type: SAMPLE_EXCEL_DOWNLOAD,
  payload: { authtoken, id  },
})

export const sampleExcelSuccess = data => ({
  type: SAMPLE_EXCEL_DOWNLOAD_SUCCESS,
  payload: data,
})

export const sampleExcelFail = error => ({
  type: SAMPLE_EXCEL_DOWNLOAD_FAIL,
  payload: error,
})
 