import {
  ADD_EXCLUSIVE_SHOP,
  ADD_EXCLUSIVE_SHOP_FAIL,
  ADD_EXCLUSIVE_SHOP_SUCCESS,
  ADD_NEW_STORE,
  ADD_STORE_FAIL,
  ADD_STORE_SUCCESS,
  DELETE_STORE,
  DELETE_STORE_FAIL,
  DELETE_STORE_SUCCESS,
  GET_ALL_STORE,
  GET_ALL_STORE_FAIL,
  GET_ALL_STORE_SUCCESS,
  GET_SEARCH_STORE,
  GET_SEARCH_STORE_FAIL,
  GET_SEARCH_STORE_SUCCESS,
  GET_STORE,
  GET_STORES,
  GET_STORES_FAIL,
  GET_STORES_SUCCESS,
  GET_STORE_ANALYTIC,
  GET_STORE_ANALYTIC_FAIL,
  GET_STORE_ANALYTIC_SUCCESS,
  GET_STORE_FAIL,
  GET_STORE_SUCCESS,
  GET_STORE_TOP_PRODUCT,
  GET_STORE_TOP_PRODUCT_FAIL,
  GET_STORE_TOP_PRODUCT_SUCCESS,
  UPDATE_STORE,
  UPDATE_STORE_FAIL,
  UPDATE_STORE_SUCCESS,
  GET_STORE_ADD_EXCEL,
  GET_STORE_ADD_EXCEL_SUCCESS,
  GET_STORE_ADD_EXCEL_FAIL,
  GET_STORE_ORDER,
  GET_STORE_ORDER_SUCCESS,
  GET_STORE_ORDER_FAIL,
  GET_VISITED_SHOP,
  GET_VISITED_SHOP_SUCCESS,
  GET_VISITED_SHOP_FAIL,
  VISITED_SHOP_ORDER_NOT,
  VISITED_SHOP_ORDER_NOT_SUCCESS,
  VISITED_SHOP_ORDER_NOT_FAIL,
  GET_SHOP_MAP,
  GET_SHOP_MAP_SUCCESS,
  GET_SHOP_MAP_FAIL,
  GET_SHOP_DETAILS_MAP,
  GET_SHOP_DETAILS_MAP_SUCCESS,
  GET_SHOP_DETAILS_MAP_FAIL,
  GET_SHOP_GRAPH,
  GET_SHOP_GRAPH_SUCCESS,
  GET_SHOP_GRAPH_FAIL
} from "./actionTypes"
export const addNewStore = (data, history, authtoken) => ({
  type: ADD_NEW_STORE,
  payload: { data, history, authtoken },
})
export const addStoreSuccess = message => ({
  type: ADD_STORE_SUCCESS,
  payload: { message },
})
export const addStoreFail = message => ({
  type: ADD_STORE_FAIL,
  payload: { message },
})
export const addExclusiveShop = (data, history, authtoken) => ({
  type: ADD_EXCLUSIVE_SHOP,
  payload: { data, history, authtoken },
})
export const addExclusiveShopSuccess = message => ({
  type: ADD_EXCLUSIVE_SHOP_SUCCESS,
  payload: { message },
})
export const addExclusiveShopFail = message => ({
  type: ADD_EXCLUSIVE_SHOP_FAIL,
  payload: { message },
})
export const getStores = (authtoken, orgid) => ({
  type: GET_STORES,
  payload: { authtoken, orgid },
})
export const getStoresSuccess = data => ({
  type: GET_STORES_SUCCESS,
  payload: data,
})
export const getStoresFail = message => ({
  type: GET_STORES_FAIL,
  payload: { message },
})
export const getStoresOrder = (authtoken, pageNo, limit) => ({
  type: GET_STORE_ORDER,
  payload: { authtoken, pageNo, limit },
})
export const getStoresOrderSuccess = data => ({
  type: GET_STORE_ORDER_SUCCESS,
  payload: data,
})
export const getStoresOrderFail = message => ({
  type: GET_STORE_ORDER_FAIL,
  payload: { message },
})
export const getStoreAnalytic = (authtoken, id) => ({
  type: GET_STORE_ANALYTIC,
  payload: { authtoken, id },
})
export const getStoresAnalyticSuccess = data => ({
  type: GET_STORE_ANALYTIC_SUCCESS,
  payload: data,
})
export const getStoresAnalyticFail = message => ({
  type: GET_STORE_ANALYTIC_FAIL,
  payload: { message },
})
export const getStore = (authtoken, id) => ({
  type: GET_STORE,
  payload: { authtoken, id },
})
export const getStoreSuccess = (data, authtoken) => ({
  type: GET_STORE_SUCCESS,
  payload: { data, authtoken },
})
export const getStoreFail = error => ({
  type: GET_STORE_FAIL,
  payload: error,
})
export const getStoreTopProduct = (authtoken, id) => ({
  type: GET_STORE_TOP_PRODUCT,
  payload: { authtoken, id },
})
export const getStoreTopProductSuccess = (data, authtoken) => ({
  type: GET_STORE_TOP_PRODUCT_SUCCESS,
  payload: { data, authtoken },
})
export const getStoreTopProductFail = error => ({
  type: GET_STORE_TOP_PRODUCT_FAIL,
  payload: error,
})
export const updateStore = (values, storeid, history, authtoken) => ({
  type: UPDATE_STORE,
  payload: { values, storeid, history, authtoken },
})
export const updateStoreSuccess = message => ({
  type: UPDATE_STORE_SUCCESS,
  payload: { message },
})
export const updateStoreFail = message => ({
  type: UPDATE_STORE_FAIL,
  payload: { message },
})
export const deleteStore = (id, authtoken) => ({
  type: DELETE_STORE,
  payload: { id, authtoken },
})
export const deleteStoreSuccess = data => ({
  type: DELETE_STORE_SUCCESS,
  payload: data,
})
export const deleteStoreFail = message => ({
  type: DELETE_STORE_FAIL,
  payload: { message },
})
export const getAllStore = (authtoken, orgId, currentPage, shopName) => ({
  type: GET_ALL_STORE,
  payload: { authtoken, orgId, currentPage, shopName },
})
export const getAllStoreSuccess = (data, authtoken) => ({
  type: GET_ALL_STORE_SUCCESS,
  payload: { data, authtoken },
})
export const getAllStoreFail = error => ({
  type: GET_ALL_STORE_FAIL,
  payload: error,
})
export const getSearchStore = (authtoken, shopName) => ({
  type: GET_SEARCH_STORE,
  payload: { authtoken, shopName },
})
export const getSearchStoreSuccess = (data, authtoken) => ({
  type: GET_SEARCH_STORE_SUCCESS,
  payload: { data, authtoken },
})
export const getSearchStoreStoreFail = error => ({
  type: GET_SEARCH_STORE_FAIL,
  payload: error,
})
export const getStoreEXCEL = (authtoken, id) => ({
  type: GET_STORE_ADD_EXCEL,
  payload: { authtoken, id },
})
export const getStoreEXCELSuccess = (data, authtoken) => ({
  type: GET_STORE_ADD_EXCEL_SUCCESS,
  payload: { data, authtoken },
})
export const getStoreEXCELFail = error => ({
  type: GET_STORE_ADD_EXCEL_FAIL,
  payload: error,
})

export const getVisitedShop = (authtoken, pageNo, limit) => ({
  type: GET_VISITED_SHOP,
  payload: { authtoken, pageNo, limit },
})
export const getVisitedShopSuccess = data => ({
  type: GET_VISITED_SHOP_SUCCESS,
  payload: data,
})
export const getVisitedShopFail = message => ({
  type: GET_VISITED_SHOP_FAIL,
  payload: { message },
})
export const getVisitedShopNotOrder = (authtoken, fromDate, toDate, pageNo, limit, region) => ({
  type: VISITED_SHOP_ORDER_NOT,
  payload: { authtoken, fromDate, toDate, pageNo, limit, region },
})
export const getVisitedShopNotOrderSuccess = data => ({
  type: VISITED_SHOP_ORDER_NOT_SUCCESS,
  payload: data,
})
export const getVisitedShopNotOrderFail = message => ({
  type: VISITED_SHOP_ORDER_NOT_FAIL,
  payload: { message },
})

export const getShopGraph = (authtoken, id) => ({
  type: GET_SHOP_GRAPH,
  payload: { authtoken, id},
})
export const getShopGraphSuccess = data => ({
  type: GET_SHOP_GRAPH_SUCCESS,
  payload: data,
})
export const getShopGraphFail = message => ({
  type: GET_SHOP_GRAPH_FAIL,
  payload: { message },
})


export const getShopMap = (authtoken, page, limit, region, area, territory, searchFilter) => ({
  type: GET_SHOP_MAP,
  payload: { authtoken, page, limit, region, area, territory, searchFilter},
})
export const getShopMapSuccess = data => ({
  type: GET_SHOP_MAP_SUCCESS,
  payload: data,
})
export const getShopMapFail = message => ({
  type: GET_SHOP_MAP_FAIL,
  payload: { message },
})

export const getShopDetailsMap = (authtoken, shopId) => ({
  type: GET_SHOP_DETAILS_MAP,
  payload: { authtoken, shopId},
})
export const getShopDetailsMapSuccess = data => ({
  type: GET_SHOP_DETAILS_MAP_SUCCESS,
  payload: data,
})
export const getShopDetailsMapFail = message => ({
  type: GET_SHOP_DETAILS_MAP_FAIL,
  payload: { message },
})